import { ONE_DAY } from '@/constants/gpc'
import type { APIResponse } from '@/types/api'
import { APIError } from './error'

// biome-ignore lint/suspicious/noExplicitAny: for type inferring
type SecondParamType<T> = T extends (arg1: any, arg2: infer U, ...args: any[]) => any ? U : never
export type OptionsType = SecondParamType<typeof fetch>
export type requestProps = { options?: OptionsType } & (
  | {
      url: string
      path?: never
    }
  | {
      path: string
      url?: never
    }
)

const next = { revalidate: 7 * ONE_DAY }

export const request = async ({ url, path, options = {} }: requestProps) => {
  const urlToFetch = url ?? `${process.env.NEXT_PUBLIC_PULSE_API_URL}${path}`
  return fetch(urlToFetch, { cache: 'force-cache', next, ...options })
}

export const requestJSON = async <T>(props: requestProps, defaultValue?: T) => {
  const response = await request(props)
  const failedResponse = !response.ok
  if (failedResponse && typeof defaultValue !== 'undefined') {
    return defaultValue
  }
  if (failedResponse) {
    const errorObj = { ...props, status: response.status }
    const errorMsg = `Error while calling ${JSON.stringify(errorObj)}`
    if (response.status >= 400 && response.status <= 500) {
      const json: APIResponse<unknown> = await response.json()
      throw new APIError(response.status, JSON.stringify(json))
    }
    throw new Error(errorMsg)
  }

  const json = await response.json()

  // Temporary fix to get different data structures from the API without modyfing existing
  // requestJSON calls. This will be fixed in a future PR.
  return json.data ?? json
}
