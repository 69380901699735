export const TOKEN_TYPE = 'token-type'
export const HEADER_TOKEN = 'header-token'
export const UNAUTHORIZED = 'unauthorized'

const STORAGE_KEY = 'store'
export const STORAGE_AUTH_ITEMS = [TOKEN_TYPE, HEADER_TOKEN, STORAGE_KEY]

export const TOKEN_TYPE_HEADER = 'Token-Type'
export const CONTRIBUTION_SOURCE_HEADER = 'Contribution-Source'

export const PREFER_LOGIN = 'prefer_login'
export const PREFER_SIGNUP = 'prefer_signup'

export const STEP_LOGIN = 'login'
export const STEP_SIGNUP = 'signup'

export const REDIRECT_PARAMS = [PREFER_LOGIN, PREFER_SIGNUP] as const
const AUTH_STEPS = [STEP_LOGIN, STEP_SIGNUP] as const

const SCOPE = 'openid profile'
const RESPONSE_TYPE_CODE = 'code'
const RESPONSE_MODE_QUERY = 'query'
const CODE_CHALLENGE_METHOD = 'S256'
const CLIENT_ID = 'pi-community-web'

export const DEFAULT_AUTH_PARAMS = {
  client_id: CLIENT_ID,
  code_challenge_method: CODE_CHALLENGE_METHOD,
  response_type: RESPONSE_TYPE_CODE,
  response_mode: RESPONSE_MODE_QUERY,
  scope: SCOPE,
} as const

export const PREFER_STEP_MAP: StepTypeMap = {
  [PREFER_LOGIN]: STEP_LOGIN,
  [PREFER_SIGNUP]: STEP_SIGNUP,
} as const

const ACTIVITY_TYPE_POST = 'post'
const ACTIVITY_TYPE_POLL = 'poll'
const ACTIVITY_TYPE_SURVEY = 'survey'
export const ACTIVITY_TYPE_OTHERS = 'others'

export const ACTIVITY_TYPE_KEY = [
  ACTIVITY_TYPE_POST,
  ACTIVITY_TYPE_POLL,
  ACTIVITY_TYPE_SURVEY,
  ACTIVITY_TYPE_OTHERS,
] as const

export const ACTIVITY_TYPE_VALUE_POST = 'qna_activity'
export const ACTIVITY_TYPE_VALUE_POLL = 'poll_activity'
export const ACTIVITY_TYPE_VALUE_SURVEY = 'survey_activity'
export const ACTIVITY_TYPE_VALUE_OTHERS = 'others'
const ACTIVITY_TYPE_VALUE = [
  ACTIVITY_TYPE_VALUE_POST,
  ACTIVITY_TYPE_VALUE_POLL,
  ACTIVITY_TYPE_VALUE_SURVEY,
  ACTIVITY_TYPE_VALUE_OTHERS,
] as const
export const ACTIVITY_TYPE_MAP: ActivityTypeMap = {
  post: 'qna_activity',
  poll: 'poll_activity',
  survey: 'survey_activity',
  others: 'others',
}

export const LOGIN_REDIRECT_KEY = 'loginRedirect'

export type StepType = (typeof AUTH_STEPS)[number]
type StepParamType = (typeof REDIRECT_PARAMS)[number]
type ActivityType = (typeof ACTIVITY_TYPE_KEY)[number]
export type ActivityValueType = (typeof ACTIVITY_TYPE_VALUE)[number]
type ActivityTypeMap = {
  [x in ActivityType]: ActivityValueType
}
type StepTypeMap = {
  [x in StepParamType]: StepType
}

export const USER_CONTRIBUTION_REF = 'ref'
export const USER_CONTRIBUTION_GXTM_SOURCE = 'gxtm_source'
export const USER_CONTRIBUTION_GXTM_PAGE = 'gxtm_page'
export const USER_CONTRIBUTION_UTM_SOURCE = 'utm_source'

export const AUTH_REVALIDATE = 500
