import ReactDOM from 'react-dom'
import type { ModalType } from '../../ModalProvider'

const ModalRoot = ({ modals }: { modals: Record<string, ModalType> }) => {
  if (typeof document === 'undefined') {
    return null
  }
  return ReactDOM.createPortal(
    Object.keys(modals).map((key) => {
      const Component = modals[key]
      return <Component key={key} />
    }),
    document.body
  )
}

export default ModalRoot
