import type { LogoutCommentType, PublicCommentType } from '@/types/api'
import type { DetailedHTMLProps, HTMLAttributes, TextareaHTMLAttributes } from 'react'

export type HtmlProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
export type SpanProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>
export type DivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
export type LiProps = DetailedHTMLProps<HTMLAttributes<HTMLLIElement>, HTMLLIElement>
export type FormProps = DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>
export type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
export type SectionProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
export type TextareaProps = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>

export type EventWithData = Element & {
  dataset?: {
    evna: string
    evco: string
    evob: string
    evpk: string
    evpla: string
    evpg: string
  }
}
export type GAEvent = {
  name?: string
  component?: string
  object?: string
  pk?: string
  platform?: string
  page_name?: string
  [key: string]: unknown
}

export enum Size {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum CommentSortBy {
  oldest = 'oldest',
  newest = 'newest',
  mostUpvoted = 'mostUpvoted',
}

export type PublicCommentSortFn = (
  a: PublicCommentType | LogoutCommentType,
  b: PublicCommentType | LogoutCommentType
) => number

export type ContentConfigType = {
  type: 'post' | 'poll' | 'omi'
  slug: string
}

export type ServerActionResponse<T> = {
  data?: T
  error?: { status: number; message?: string }
}

// https://blog.logrocket.com/build-strongly-typed-polymorphic-components-react-typescript/
export type AsProp<T extends React.ElementType> = { as?: T }
export type PropsToOmit<T extends React.ElementType, P> = keyof (AsProp<T> & P)
export type PolymorphicComponentProp<T extends React.ElementType, Props = {}> = Omit<
  React.ComponentPropsWithoutRef<T>,
  PropsToOmit<T, Props>
> &
  React.PropsWithChildren<Props & AsProp<T>>
export type PolymorphicRef<T extends React.ElementType> = React.ComponentPropsWithRef<T>['ref']
export type PolymorphicComponentPropWithRef<T extends React.ElementType, Props = {}> = PolymorphicComponentProp<
  T,
  Props
> & { ref?: PolymorphicRef<T> }

export type PossiblyNull<T> = T | null

export type KeysOf<T> = {
  [K in keyof T]: K
}[keyof T]

/**
 * A dictionary that maps specific API fields to their corresponding ViewModel fields.
 *
 * This is used to handle custom conversions for fields that do not follow a predictable naming convention.
 * Fields listed here will be explicitly converted, while others will remain as-is.
 */
const ApiToViewModelFieldMap = {
  chartdata: 'chartData',
  authorid: 'authorId',
} as const

/**
 * Type representing the structure of the `ApiToViewModelFieldMap` object.
 */
type ApiToViewModelFieldMap = typeof ApiToViewModelFieldMap

/**
 * Removes a leading underscore (`_`) from a string, if present.
 *
 * @template S - The input string.
 * @example
 * type Result = RemoveLeadingUnderscore<'_id'>; // 'id'
 */
type RemoveLeadingUnderscore<S extends string> = S extends `_${infer Word}` ? Word : S

/**
 * Maps a string to a custom field name based on the `ApiToViewModelFieldMap` object.
 * If the string is found as a key in the map, it returns the corresponding value.
 * Otherwise, it returns the string as-is.
 *
 * @template S - The input string.
 * @example
 * type Result1 = MappedField<'chartdata'>; // 'chartData'
 * type Result2 = MappedField<'unknown_field'>; // 'unknown_field'
 */
type MappedField<S extends string> = S extends keyof ApiToViewModelFieldMap ? ApiToViewModelFieldMap[S] : S

/**
 * Maps an API field name in snake_case to a ViewModel field name in camelCase.
 * This includes custom field mappings from the `ApiToViewModelFieldMap`.
 *
 * @template S - The input string representing an API field.
 * @example
 * type Result = ApiFieldToViewModelField<'chartdata'>; // 'chartData'
 */
type ApiFieldToViewModelField<S extends string> = MappedField<SnakeCaseToCamelCase<S>>

/**
 * Converts a string from snake_case to camelCase, removing leading underscores and capitalizing
 * words after underscores. Custom field mappings are applied from `ApiToViewModelFieldMap`.
 *
 * @template S - The input string.
 * @example
 * type Result1 = SnakeCaseToCamelCase<'snake_case_field'>; // 'snakeCaseField'
 * type Result2 = SnakeCaseToCamelCase<'_id'>; // 'id'
 */
type SnakeCaseToCamelCase<S extends string> = RemoveLeadingUnderscore<S> extends `${infer Head}_${infer Tail}`
  ? `${Head}${Capitalize<SnakeCaseToCamelCase<Tail>>}`
  : RemoveLeadingUnderscore<S>

/**
 * Transforms an object's keys from snake_case (API format) to camelCase (ViewModel format),
 * applying custom field mappings from the `ApiToViewModelFieldMap`.
 *
 * @template T - The input object type.
 * @example
 * type Input = {
 *   chartdata: number;
 *   snake_case_field: string;
 *   _id: boolean;
 * };
 * type Result = SnakeCaseObjectToCamelCaseObject<Input>;
 * // {
 * //   chartData: number; // Translated via ApiToViewModelFieldMap
 * //   snakeCaseField: string; // Transformed to camelCase
 * //   id: boolean; // Transformed to camelCase
 * // }
 */
type SnakeCaseObjectToCamelCaseObject<T extends object> = {
  [K in keyof T as ApiFieldToViewModelField<K & string>]: T[K]
}

/**
 * Alias for `SnakeCaseObjectToCamelCaseObject`
 */
export type ApiTypeToViewModelType<T extends object> = SnakeCaseObjectToCamelCaseObject<T>

/**
 * Excludes specific keys from an object type.
 *
 * Unlike `Omit`, this implementation ensures better IDE support for autocompletion when selecting keys to exclude.
 */
export type Except<T, K extends keyof T> = { [P in keyof T as P extends K ? never : P]: T[P] }
