import events from '@/services/events'

const routeMap = [
  { path: '/poll', name: 'Poll detailed view' },
  { path: '/post', name: 'Long-form detailed view' },
  { path: '/oneminuteinsights/', name: 'One-Minute Insights DetailView Page' },
  { path: '/oneminuteinsights', name: 'One-Minute Insights' },
  { path: '/home', name: 'Home Page' },
  { path: '/ambassadors', name: 'Ambassadors Page' },
  { path: '/discussions/', name: 'Discussions DetailView Page' },
  { path: '/discussions', name: 'Discussions Page' },
  { path: '/profile', name: 'Profile DetailView Page' },
  { path: '/business-community', name: 'Business Community Landing' },
  { path: '/technology-community', name: 'Technology Community Landing' },
]

export const getPageName = (pathname: string) => {
  const route = routeMap.find(({ path }) => pathname.includes(path))
  return route?.name ?? 'UNSET'
}

export const getPageType = (pathname: string) => (pathname.includes('/home') ? 'Home' : 'Content')

export const getGtagValue = (metric: 'session_id' | 'client_id'): Promise<string> => {
  return new Promise((resolve) => {
    window.gtag('get', process.env.NEXT_PUBLIC_GA_TRACKING_ID, metric, (value: string) => resolve(value))
  })
}

export const getAnalyticsIds = async (timeout = 5000) => {
  const getIdsFromGA = Promise.all([getGtagValue('session_id'), getGtagValue('client_id')])

  const getIdsFallback = new Promise<[string, string]>((resolve) => {
    setTimeout(() => {
      resolve(['', ''])
    }, timeout)
  })

  return await Promise.any([getIdsFromGA, getIdsFallback])
}

export const sendAnalyticsEvent = async ({ pathname }: { pathname: string }) => {
  const [sessionId, clientId] = await getAnalyticsIds()
  await events.postCustomEvent({
    event: 'page_view',
    page: pathname,
    properties: {
      title: document.title,
      is_mobile: false,
      clientId,
      session_id: sessionId,
      page_name: getPageName(pathname),
      page_type: getPageType(pathname),
    },
  })
}
